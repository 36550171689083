<template>
    <div class="region-wrapper">
        <main class="region-content" @click="fun">
            <slot/>
        </main>
    </div>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RegionWrapper',
  components: {
    ...importComponents(
    ),
  },
  setup() {
    const fun = (e: MouseEvent) => {
      e.stopPropagation();
    };
    return {
      fun,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import '@/assets/styles/variables.scss';

.region-wrapper {
    @include flex-center();
    width: 100%;
    background: #F4F4F4;
    border-bottom: 3px solid #e1e1e1;
    .region-content{
        @include flex-center();
        flex-direction: column;
        width: 1280px;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px 0px rgba(37, 37, 37, 0.16);
        border-radius: 12px;
        margin-top: 50px;
        margin-bottom: 117px;
    }
}
</style>
