
import type { ValidationRule } from 'ant-design-vue/lib/form/Form';
import importComponents from '@/utils/import-components';
import {
  defineComponent, reactive, onBeforeMount, ref,
} from 'vue';
import RegionWrapper from '@/components/common/RegionWrapper.vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import { useForm } from '@ant-design-vue/use';
import {
  Form, Button,
} from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { updateUserName, getUserInfo } from '@/services/user';
import { Alert } from '@/components/Alert/index';
import Cookies from 'js-cookie';
import { useStore } from 'vuex';
import Load from '@/components/common/Load.vue';
import Input from '@/components/common/Input.vue';
import { useI18n } from 'vue-i18n';

type FormRules = Record<string, ValidationRule | ValidationRule[]>;

type ValidatorRule = {
  field: string;
  fullField: string;
  trigger: string;
  type: string;
}

type ValidatorFunc = (rule: ValidationRule, value: string) => Promise<void>;

export default defineComponent({
  name: 'ChangeName',
  components: {
    ItemHeader,
    RegionWrapper,
    Load,
    Input,
    ...importComponents(
      Form,
      Form.Item,
      Button,
    ),
  },
  setup() {
    const { t } = useI18n();
    const loading = ref(true);
    const changeLoading = ref(false);
    const { commit, state } = useStore();
    const router = useRouter();
    const onCancel = () => {
      router.push('/user/profile');
    };

    const form = reactive({
      firstName: '',
      lastName: '',
    });

    // Form表单自定义校验规则
    const rejectFunc = (reason: string) => Promise.reject(new Error(reason));
    const firstName: ValidatorFunc = (rule, value) => {
      if (value.trim() === '') {
        return rejectFunc(t('changeName.enterFirst'));
      }
      return Promise.resolve();
    };
    const lastName: ValidatorFunc = (rule, value) => {
      if (value.trim() === '') {
        return rejectFunc(t('changeName.enterLast'));
      }
      return Promise.resolve();
    };
    // 合并校验规则
    const rules = reactive<FormRules>({
      firstName: [{ validator: firstName, required: true, trigger: 'change' }],
      lastName: [{ validator: lastName, required: true, trigger: 'change' }],
    });
    const {
      validate,
      validateInfos,
    } = useForm(form, rules);

    // 获取用户信息
    const userIdValue = ref();
    const getUser = async () => {
      try {
        const res = await getUserInfo();
        if (res.success) {
          form.firstName = res.data.userFirstName;
          form.lastName = res.data.userLastName === null ? '' : res.data.userLastName;
          userIdValue.value = res.data.userId;
          loading.value = false;
        }
      } catch (e) {
        console.log('error');
      }
    };
    onBeforeMount(() => {
      if (Cookies.get('token')) {
        getUser();
      }
    });

    // 表单提交按钮
    const onUpdate = async () => {
      try {
        const res = await validate();
        if (res) {
          // 跟原来的一样的时候
          const { userFirstName, userLastName } = state.userInfo;
          if (res.firstName === userFirstName && res.lastName === userLastName) {
            changeLoading.value = true;
            const modal = Alert.success(t('changeName.updateSuccess'));
            setTimeout(() => {
              modal.destroy();
              router.push('/user/profile');
            }, 2000);
            return;
          }
          // 跟原来的不一样的时候 调接口
          const data = {
            userFirstName: res.firstName,
            userId: userIdValue.value,
            userLastName: res.lastName,
          };
          try {
            const result = await updateUserName(data);
            changeLoading.value = true;
            if (result.success) {
              // 更新store里的用户名 不然返回上一页的时候信息不同步
              commit('updateUserName', { userLastName: res.lastName, userFirstName: res.firstName });
              changeLoading.value = false;
              const modal = Alert.success(t('changeName.updateSuccess'));
              setTimeout(() => {
                modal.destroy();
                router.push('/user/profile');
              }, 2000);
            } else {
              const modal = Alert.error(result.message);
              setTimeout(() => {
                modal.destroy();
              }, 2000);
            }
          } catch (e) {
            console.error(e);
          }
        }
      } catch (e) {
        console.log('error');
      }
    };

    return {
      form,
      validateInfos,
      onUpdate,
      onCancel,
      loading,
      changeLoading,
      t,
    };
  },
});
