
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RegionWrapper',
  components: {
    ...importComponents(
    ),
  },
  setup() {
    const fun = (e: MouseEvent) => {
      e.stopPropagation();
    };
    return {
      fun,
    };
  },
});
