<template>
    <div :class="['input-style', {'focus-in': focusIn}]" tabindex="-1" @focusin="clearFocusToggle('in')" @focusout="clearFocusToggle('out')">
        <input
        :value="value"
        :placeholder="placeholder"
        :type="inputType"
        :readonly='readonly'
        @input="onInput">
        <span class="icons">
            <svg :class="['clear',{'click-clear': clickClear}]" v-if="clear&&value" @click="clickClearFunc"
            width="12px" height="12px" viewBox="64 64 896 896">
                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512
                64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5
                359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9
                5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"/>
            </svg>
            <span v-if="type === 'password'&& eye">
                <svg class="eye close" width="14px" height="14px" viewBox="64 64 896 896" @click="eyeToggle('text')" v-if="inputType === 'password'">
                    <path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45
                    447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323
                    798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32
                    0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8
                    8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54
                    0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7
                    0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"/>
                    <path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82
                    52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"/>
                </svg>
                <svg class="eye open" width="14px" height="14px" viewBox="64 64 896 896" @click="eyeToggle('password')" v-if="inputType === 'text'">
                    <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000
                    51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3
                    0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512
                    766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0
                    288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"/>
                </svg>
            </span>
        </span>
    </div>
</template>
<script lang='ts'>
import {
  defineComponent, ref,
} from 'vue';

export default defineComponent({
  name: 'Input',
  components: {
  },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    clear: {
      type: Boolean,
    },
    type: {
      type: String,
      default: 'text',
    },
    eye: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    // input容器focus样式
    const focusIn = ref(false);
    // 控制清除
    const clearing = ref(false);
    // 控制清除按钮点击样式
    const clickClear = ref(false);
    // 控制输入框类型和眼睛
    const inputType = ref(props.type);

    const onInput = (e: KeyboardEvent) => {
      ctx.emit('update:value', (e.target as HTMLInputElement).value);
    };

    const clearFocusToggle = (str: string) => {
      // eslint-disable-next-line no-unused-expressions
      str === 'in' ? focusIn.value = true : focusIn.value = false;
    };

    const clickClearFunc = () => {
      // icon颜色点击变深
      clickClear.value = true;
      setTimeout(() => {
        clickClear.value = false;
      }, 1000);
      // 清除内容
      ctx.emit('update:value', '');
      // icon消失
      clearing.value = false;
    };

    const eyeToggle = (type: string) => {
      inputType.value = type;
    };

    return {
      onInput,
      focusIn,
      clearFocusToggle,
      clickClear,
      clickClearFunc,
      clearing,
      eyeToggle,
      inputType,
    };
  },
});
</script>
<style lang='scss' scoped>
@import '@/assets/styles/mixin.scss';
@import '@/assets/styles/variables.scss';
// 输入框样式
.input-style{
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  height: 40px;
  padding: 4px 10px;
  background: #FFFFFF;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.85);
  text-align: start;
  transition: all 200ms;
  &:hover {
    border: 1px solid $theme-color;
    box-shadow: none;
  }
  &:focus, &.focus-in {
    border: 1px solid $theme-color;
    box-shadow: 0 0 0 2px rgba(230, 0, 44, 0.2)
  }
  input{
    height: 100%;
    flex: 1;
    outline: none;
    border: none;
  }
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #fff;
    svg{
      margin-left: 4px;
      cursor: pointer;
      &.clear{
        @keyframes clear {
          0% {
            fill: #BFBFBF;
          }
          100% {
            fill: #8C8C8C;
          }
        }
        fill: #BFBFBF;
        &:hover{
          animation: clear 500ms;
          fill:#8C8C8C;
          &.click-clear{
            fill: rgba(0, 0, 0, 0.85);
          }
        }
    }
    &.eye{
      @keyframes eye {
        0% {
          fill: #8C8C8C;
        }
        100% {
          fill:rgba(0, 0, 0, 0.85);
        }
      }
      fill:#8C8C8C;
      &:hover{
        animation: eye 500ms;
        fill:rgba(0, 0, 0, 0.85);
      }
    }
      }
  }
}
</style>
