
import {
  defineComponent, ref,
} from 'vue';

export default defineComponent({
  name: 'Input',
  components: {
  },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    clear: {
      type: Boolean,
    },
    type: {
      type: String,
      default: 'text',
    },
    eye: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    // input容器focus样式
    const focusIn = ref(false);
    // 控制清除
    const clearing = ref(false);
    // 控制清除按钮点击样式
    const clickClear = ref(false);
    // 控制输入框类型和眼睛
    const inputType = ref(props.type);

    const onInput = (e: KeyboardEvent) => {
      ctx.emit('update:value', (e.target as HTMLInputElement).value);
    };

    const clearFocusToggle = (str: string) => {
      // eslint-disable-next-line no-unused-expressions
      str === 'in' ? focusIn.value = true : focusIn.value = false;
    };

    const clickClearFunc = () => {
      // icon颜色点击变深
      clickClear.value = true;
      setTimeout(() => {
        clickClear.value = false;
      }, 1000);
      // 清除内容
      ctx.emit('update:value', '');
      // icon消失
      clearing.value = false;
    };

    const eyeToggle = (type: string) => {
      inputType.value = type;
    };

    return {
      onInput,
      focusIn,
      clearFocusToggle,
      clickClear,
      clickClearFunc,
      clearing,
      eyeToggle,
      inputType,
    };
  },
});
